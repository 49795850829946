<template>
  <div class="Slideshow">
    <div class="top-title">
      <div class="top-text">轮播图</div>

    </div>

    <!-- 选择列表  表格-->
    <div class="table-form">
      <el-table :data="list" v-loading="loading">
         <el-table-column label="序号" width="70px">
           <template slot-scope="scope"> {{scope.$index+1}} </template>
          </el-table-column>
         <el-table-column label="轮播图"> 
            <template slot-scope="scope">
                    <img :src="ImgUrl+scope.row.slideshowImg" width="333" height="100"/>
            </template>
        </el-table-column> 
       
        <el-table-column label="跳转类型">
          <template slot-scope="scope"> 
           {{scope.row.ofType == 2 ?"不跳转":scope.row.ofType == 1 ?"详情页":scope.row.ofType == 0 ?"宣传图":''}}
             </template>
           </el-table-column>

        <el-table-column label="操作" width="200" class="table-form-btn">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row)"
              class="table-form-btn"
              type="text">详情</el-button>
          </template>
        </el-table-column>
      </el-table>

    </div>
  </div>
</template>
<script>
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js"
export default {
  data() {
    return {
      loading: this.$common.loading,
      list:[],
      ImgUrl:config.ImgUrl,
    };
  },
  created() {
    this.getSong()
  },
  mounted() {},

  methods: {

    //查询接口
      async getSong(){
				let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        handleMode:'fetchall',
        bizCatalog:'CyclePicShowEntity',
        })
  
        if(this.$common.verifyAjaxResult(res,false)==false) return;
          this.list = res.Result;
          this.loading = false
      },


    // 表格根据停用次数改变背景
    tableRowStyle({ row, rowIndex }) {
      if (row.leixing == 2) { return "block-up-one"; } else if (row.leixing == 4) { return "block-up-two"}
    },

    // 跳转详情页
    handleClick(row) {
      this.$router.push({ path: `/home/slideshowpar/${row.id}` });
    },

  },
  computed: {},
};
</script>


<style  scoped>
@import "../../assets/css/liststyle.css";
</style>